
.default-option{
    border-radius: 15px; 
    box-shadow:inset 4px 3.1px 5px -3px #38414a26; 
    color: #653b13;
  }
  [dir="rtl"] {
    .marked-option{
      background: linear-gradient(90deg, #000000cf 0%, #e8a23d 100%);
      border-radius: 15px;
      color: #ffff 
    }
  }
  .marked-option{
    background: linear-gradient(90deg, #e8a23d 0%, #000000cf 100%);
    border-radius: 15px;
    color: #ffff 
  }

 

  .correct-ans-style{
    background: #69c04f;
    border-radius: 15px;
    color: #ffffff;
    opacity: 1;
  }

  .wrong-ans-style{
    background: #e0546c;
    border-radius: 15px;
    color: #ffffff;
    opacity: 1;
  }

  
  .white-color{
  
    color: #ffff 
  }
    
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label, .form-check-input:disabled{
    opacity: 1;
  }
  .form-radio-secondary .form-check-input:checked{
    border-color: #402213;
    background-color: #ffffff;
  }

  .green-color{
    background:"#5b913f"
  }
  .red-color{
    background:"#cc3516"
  }
  







